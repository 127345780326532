import React from "react";
import "./layout.scss";

import Logo from "../assets/logo.png";

const Layout = (props) => {
  return (
    <div id="layout">
      <div className="color-block diagonal"></div>
      <div className="color-block skinny-rectangle"></div>
      <div className="color-block circle"></div>
      <header> <img className="logo" src={Logo} /> </header>
      <nav><a href="/links">Links</a></nav>
      <div id="main-content">
        {props.children}
      </div>
      <footer><small>&copy; The dumpster fire of a year that gave me time to do this</small></footer>
    </div>
  )
}

export default Layout;
