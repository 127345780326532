import React from "react";
import "./index.scss";

import Layout from "../components/layout";

export default function Home() {
  return <Layout>
    <div>
      <p className="intro">Hi! This is my website.</p>
    </div>
  </Layout>
}
